import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import {
	NETWORK,
	GAS_LIMIT,
	LARVA_MFERS_CONTRACT_ADDRESS,
	ERC721_NAME,
	ERC721_SYMBOL,
	ETHERSCAN_LINK,
	OPENSEA_LINK,
	TWITTER_LINK,
	MAX_SUPPLY,
	FREE_MINT_SUPPLY_LIMIT,
	HOLDER_MINT_SUPPLY_LIMIT,
	MAX_FREE_MINT,
	MAX_PAID_MINT,
	COST,
	WEI_COST,
	MEDIUM_LINK,
} from "./config";

import {
	getLarvaMfersContract,
	getMfersContract,
	getLarvaLadsContract,
} from "./utils";

import * as s from "./styles/globalStyles";
import {
	StyledLogo,
	ResponsiveWrapper,
	StyledButton,
	StyledRoundButton,
	StyledLink,
} from "./styles/homepageStyles";

import LinkIcons from "./components/LinkIcons";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
	const [web3IsAvailable, setWeb3IsAvailable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [activeAccount, setActiveAccount] = useState();

	const [larvaMferSupply, setLarvaMferSupply] = useState();
	const [freeMintIsActive, setFreeMintIsActive] = useState();
	const [paidMintIsActive, setPaidMintIsActive] = useState();

	const [larvaTokenBalance, setLarvaTokenBalance] = useState();
	const [mferTokenBalance, setMferTokenBalance] = useState();
	const [ladTokenBalance, setLadTokenBalance] = useState();

	const [amountInput, setAmountInput] = useState('1');

	// Check if the browser supports wallet on page load
	useEffect(() => {
		if (!!window && !!window.ethereum) {
			setWeb3IsAvailable(true);
		} else {
			alert("Heads up! you need to install Metamask to use this app");
		}
	}, []);

	// Fetch required contract states when valid account becomes available
	useEffect(() => {
		if (!!activeAccount) {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const larvaMfersContract = getLarvaMfersContract(provider);
			const mfersContract = getMfersContract(provider);
			const larvaLadsContract = getLarvaLadsContract(provider);

			// Get total larva mfer supply
			larvaMfersContract.totalSupply().then(result => {
				setLarvaMferSupply(ethers.utils.formatUnits(result, 0));
			});
			
			// Check wallet for larva mfers
			larvaMfersContract.balanceOf(activeAccount).then(result => {
				setLarvaTokenBalance(ethers.utils.formatUnits(result, 0));
			});

		}
	}, [activeAccount]);

	const handleNumberInputChange = e => setAmountInput(e.target.value);

	const connectWallet = async () => {
		if (web3IsAvailable) {
			setIsLoading(true);
			try {
				const accounts = await window.ethereum.request({
					method: "eth_requestAccounts",
				});

				if (parseInt(window.ethereum.networkVersion) !== NETWORK.CHAIN_ID) {
					alert(
						"Invalid chain detected - must connect to the Ethereum Mainnet!"
					);
					setIsLoading(false);
					return;
				}

				if (accounts.length > 0) {
					setActiveAccount(accounts[0]);
					window.ethereum.on("accountsChanged", () => window.location.reload());
					window.ethereum.on("chainChanged", () => window.location.reload());
				} else {
					alert("No valid Ethereum addresses found!");
				}
			} catch (error) {
				console.error(error);
				alert("Error connecting wallet! Are you logged in to MetaMask?");
			}
			setIsLoading(false);
		}
	};


	const renderContent = () => {
		if (!web3IsAvailable) {
			return (
				<h1>
					please install{" "}
					<a href="https://metamask.io" target="_blank" rel="noreferrer">
						metamask
					</a>
				</h1>
			);
		}

		if (isLoading) {
			return <h1>LOADING...</h1>;
		}

		if (!activeAccount) {
			return (
				<div>
					<button style={{
						padding: "10px",
						borderradius: "50px",
						border: "none",
						background: "dodgerblue",
						padding: "12px",
						fontSize: "16px",
						fontWeight: "bold",
						color: "000000",
						width: "100px",
						cursor: "pointer",
						boxshadow: "0px 6px 0px -2px rgba(250, 250, 250, 0.3)",
					}}
					onClick={() => connectWallet()}>connect wallet</button>
				</div>
			);
		}

		// 
			return (
				<div>
					<p style={{
                		textAlign: "center",
                		fontSize: 18,
              			}}
					>
					<p>wallet connected:</p>
					<code>{truncate(activeAccount, 15)}</code>
					<br /><br /><p>
						larva mfers owned: <code>{larvaTokenBalance}</code>
					</p><br />
					</p><hr /><br />
					{larvaTokenBalance > 0 ? (
						<div>
							{larvaTokenBalance > 0 && (
								<div>
									<em>larva mfer hodler detected!</em>
								</div>
							)}
						</div>
					) : (
						<div><p style={{
							textAlign: "center",
							fontSize: 18,
							fontWeight: "bold",
							  }}>this site is currently for larva mfer hodlers only!</p></div>
					)}
				</div>
			);

	};

	return (
		<s.Screen>
			<s.Container
				flex={1}
				ai={"center"}
				style={{ padding: 24, backgroundColor: "var(--primary)" }}
			>
				<StyledLogo alt={"logo"} src={"/images/larvamferbanner.png"} />
				<s.SpacerSmall />
				<ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
					<s.Container
						flex={2}
						jc={"center"}
						ai={"center"}
						image={"/images/larvamferbest.png"}
					></s.Container>
					<s.SpacerLarge />
					<s.Container
						flex={2}
						jc={"center"}
						ai={"center"}
						style={{
							backgroundColor: "var(--accent)",
							padding: 24,
							borderRadius: 24,
							border: "4px ridge var(--secondary)",
							boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
						}}
					>
					<s.TextTitle
              			style={{
                		textAlign: "center",
                		fontSize: 60,
                		fontWeight: "bold",
                		color: "var(--accent-text)",
              			}}
            		>
              			LARVA MFERS
            		</s.TextTitle>
            		<s.TextSubTitle
            			style={{
              				textAlign: "center",
              				color: "var(--primary-text)",
            			}}
          			>
            			after months of computerart.eth perfecting the world's greatest art and zhoug.eth injecting them with maximum utility, 
						the larva mfers have escaped the lab and have been unleashed into the wild.  we tried to warn everyone about 
						these mischievous little squimers but now it's too late.  
          			</s.TextSubTitle>	
					<s.SpacerMedium />
					<s.TextTitle style={{textAlign: "center",fontWeight: "bold",}}>
						stay away from larva mfers they're dangero.... <br/>
						OH NO!!!
					</s.TextTitle>					
					</s.Container>
					<s.SpacerLarge />
					<s.Container
						flex={2}
						jc={"center"}
						ai={"center"}
						image={"/images/bargain_bin_meme.png"}
					></s.Container>
				</ResponsiveWrapper>

				<LinkIcons/>
				<s.SpacerSmall />
				<StyledLink target={"_blank"} href={MEDIUM_LINK}>
                		medium whitepaper
              		</StyledLink>
				<s.SpacerMedium />
				<s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
					<s.TextDescription style={{ textAlign: "center",}}>
						LARVA MFERS r generated entirely from hand drawings by computerart.eth. <br />
						this project is in the public domain; feel free to use LARVA MFERS any way u want. <br />
						inspired by sartoshi. image engine and contract by zhoug.eth. memes by kml. site by s34nm4c.eth
					</s.TextDescription>
					<s.SpacerSmall />
				</s.Container>
			</s.Container>
		</s.Screen>
	);
}

export default App;
